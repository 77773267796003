@import 'fonts';
@import 'colors';
@import 'inheritance';
@import 'mixin';
@import 'responsive';

a.cbtn1,
button.cbtn1,
.cbtn1 {
    @extend %primary_btn;
}

a.readmore,
button.readmore,
input.readmore {
    font-size: $small_font_size;
    font-weight: $wightRegular !important;
    color: $body_color;
    padding: 10px 18px;
    border: 1px solid $readmore_border_color;
    line-height: 20px !important;
    margin-bottom: 20px;

    &:hover {
        background: $green_regiohealth;
        color: $white !important;
        border-color: transparent;
    }
}

.download_btn {
    .widget-title {
        margin-bottom: 10px;
    }

    .download_btn_group {
        a.app_btn {
            position: relative;
            z-index: 2;
            display: inline-block;
            font-size: 12px;
            padding-left: 39px;
            border: 1px solid $app_btn_color;
            padding: 13px 20px 13px 40px;
            background: #f2f6f8;
            border-radius: 3px;
            color: $app_btn_color;
            line-height: 12px;
            @include transition(all 0.3s);

            span {
                display: block;
                line-height: 10px;
                color: $body_color;
                font-size: 15px;
                margin-top: 10px;
                text-transform: uppercase;
                @include transition(all 0.3s);
            }

            i {
                position: absolute;
                left: 10px;
                top: 50%;
                font-size: 20px;
                text-align: center;
                height: 30px;
                width: 30px;
                color: $secondary_color;
                line-height: 30px;
                margin-top: -15px;
                @include transition(all 0.3s);
            }

            &:last-child {
                margin-left: 15px;
            }

            &:hover {
                i {
                    color: $white;
                    @include transition(all 0.3s);
                }

                span {
                    color: $white;
                    @include transition(all 0.3s);
                }

                background: $secondary_color;
                border-color: $secondary_color;
                color: $white;
                @include transition(all 0.3s);
            }
        }
    }
}

.download_btn.v4 {
    .apps_desc {
        .apps_title {
            font-size: 24px;
            text-transform: capitalize;
            font-weight: 500;
        }

        position: relative;
        z-index: 2;
        padding-left: 20px;

        &:before {
            position: absolute;
            content: '';
            height: 100%;
            width: 5px;
            background: $gradient41;
            top: 0;
            left: 0;
        }
    }

    .download_btn_group {
        a.app_btn {
            position: relative;
            z-index: 2;
            display: inline-block;
            font-size: 12px;
            padding-left: 39px;
            border: 1px solid transparent;
            padding: 13px 20px 13px 40px;
            background: $gradient42;
            border-radius: 3px;
            color: $app_btn_color;
            line-height: 12px;
            @include transition(all 0.3s);

            span {
                display: block;
                line-height: 10px;
                color: $body_color;
                font-size: 15px;
                margin-top: 10px;
                font-weight: 500;
                text-transform: uppercase;
                @include transition(all 0.3s);
            }

            i {
                position: absolute;
                left: 10px;
                top: 50%;
                font-size: 20px;
                text-align: center;
                height: 30px;
                width: 30px;
                color: $secondary_color;
                line-height: 30px;
                margin-top: -15px;
                @include transition(all 0.3s);
                background: $gradient41;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            &:last-child {
                margin-left: 15px;
            }

            &:hover {
                i {
                    color: $white;
                    @include transition(all 0.3s);
                }

                span {
                    color: $white;
                    @include transition(all 0.3s);
                }

                background: $secondary_color;
                border-color: $secondary_color;
                color: $white;
                @include transition(all 0.3s);
            }
        }
    }
}

.gbtn4 {
    background: $gradient41;
}

a.showmore,
button.showmore,
input.showmore {
    font-size: 14px;
    color: $secondary_color;
    background: $show_btn_bg;
    border: 1px solid $show_btn_border;
    border-radius: 5px;
    width: 100%;
    padding: 13px;
    text-align: center;
}

a.readmore3,
button.readmore3,
input.readmore3 {
    border-style: solid;
    border-radius: 5px !important;
    border-color: $dark_grey !important;
    border-width: 2px !important;
    width: 240px !important;
    font-family: $font_open_sans_semi !important;
    font-size: $button_text !important;
    font-weight: $wightSemi !important;
    color: $dark_grey;
    text-align: center;
    // border-radius: 5px !important;
    display: inline-block;
    text-transform: uppercase;
    &:hover {
        color: $green_regiohealth;
        border-color: $green_regiohealth !important;
    }
    img {
        opacity: 0.35;
    }
}

a.readmore4,
button.readmore4,
input.readmore4 {
    font-size: 14px !important;
    font-weight: 500;
    text-transform: uppercase;
    color: $v4meta_color !important;
    position: relative;
    z-index: 2;

    &:after {
        position: absolute;
        content: '';
        height: 2px;
        width: 100%;
        bottom: 0;
        left: 0;
        background: $gradient41;
    }
}
