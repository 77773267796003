/* REGIO HEALTH COLORS */
$health_green: #7ab929;
$regio_orange: #ed6d05;
$dark_grey: #323232;
$mid_grey: #5b5b5b;
$light_grey_01: #9c9c9c;
$light_grey_02: #f1f1f1;
$white: #ffffff;

/* ---------------------  */

$body_color: #17222b;
$heading_color: #17222b;
$secondary_color: #1091ff;
$dark_color: #29333c;
$input_color: #80868b;
$dark_social_color: #c7cacc;
$footer_dark_color: #747a80;
$contact_icon: #a7a7a7;
$contact_icon_text: #8b9095;
$news_letter_body: #39424a;
$meta_date_color: #50585f;
$post_text_color: #384149;
$share_number_color: #a9adb1;
$nav_color: #c1c4c6;
$type8_counter_color: #ced0d2;
$see_all_color: #8a8f94;
$type10_meta_color: #71787d;
$author_text_color: #73797f;
$tab_deactive_color: #a0a5a8;
$tranding3_color: rgb(237, 109, 5);
$page_cm_color: #888e92;
$comment_form_border_color: #c4c7c9;
$orange_regiohealth: rgb(237, 109, 5);
$green_regiohealth: rgb(122, 185, 41);
$grey_regiohealth: rgb(112, 111, 111);
$v4meta_color: rgb(237, 109, 5);
$v4meta_color2: #ff9999;

/*DARK VERSION COLORS START*/

$dark_meta_color: #c4c7c9;
$dark_nav_color: #515a60;
$dark_post_text_color: #6a7177;
$dark_footer_submit_text_color: #6a7177;

/*DARK VERSION COLORS END*/

$app_btn_color: #4e575e;
$meta_color31: #737a7f;
$social_icon3_color: #70bdff;
$meta_col_color: #caccce;
$view_author_color: #515960;

$primary_bg: #17222b;
$secondary_bg: #1091ff;
$third_bg: #232d36;
$fourth_bg: #f0f0f1;
$fifth_bg: #fcfcfc;
$white: #ffffff;
$border_black_color: #dcdedf;
$border_solid_color: #b9bcbf;
$border_second_color: #3a434b;
$tranding_bg: rgb(237, 109, 5);
$dark_social_bg: #212c34;
$bg_white: #ffffff;
$input_bg: #e9eaeb;

$facebook_bg: #3b5998;
$facebook_bg2: #334d82;
$twitter_bg: #1da1f2;
$twitter_bg2: #1b87ca;
$youtube_bg: #ff5050;
$youtube_bg2: #d04648;
$instagram_bg: #9b45d5;
$instagram_bg2: #803db3;
$vimeo_bg: #1ab7ea;
$vimeo_bg2: #1999c3;
$medium_bg: #00ab6c;
$medium_bg2: #048f5e;
$trading_bg: rgb(237, 109, 5);
$pagination_bg: #e4e5e6;
$text_bg: #dadcdd;

$next_prev_border_bg: #bdc0c2;

/*DARK VERSION BACKGROUND START*/

$dark_footer_bg: #233340;
$dark_number_bg: #29333c;

/*DARK VERSION BACKGROUND END*/
$theme3_bg: #ecedee;

$input3_bg: #ecedee;
$trandin2_bg: #ffe5e5;
$show_btn_bg: #f2f9ff;
$more_news_border: #e7e8e9;
$news3bg: #f2f6f8;
$border4: #ecedee;
$bg4: #fafafa;
$bg4readmore_bg: #f8edf9;
$category4_bg: #e8e8ea;
$newsletter4_bg: #4f585e;
$videoAuthor_span: #8a8f94;
$videoAuthori: #fe9898;
$dots_color: #827a6f;
$scroll_bar_color: #a9acb0;
$tab4_color: #a2a7aa;

/*GRADIENT COLORS START*/

$inner_gradient_bg: linear-gradient(to left, transparent, #17222b);
$gradient_bg2: linear-gradient(0deg, rgba(0, 0, 0, 0.7), transparent);
$gradient41: linear-gradient(90deg, #ff5555, #ab50ef);
$gradient42: linear-gradient(90deg, #feedee, #f7eefd);
$gradient43: linear-gradient(to top, #000, transparent);

/*GRADINET COLORS END*/

/*SHADOW START*/

$shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
$shadow2: 0 2px 16px rgba(0, 0, 0, 0.1);
$shadow3: 0 10px 40px rgba(0, 0, 0, 0.1);
$shadow4: 0 10px 40px rgba(0, 0, 0, 0.15);
$shadow5: 0 72px 20px 0 #fbfbff;
$shadow6: 0 2px 10px rgba(0, 0, 0, 0.1);

$shadow7: 0 3px 6px #f1f1f1;

/*SHADOW END*/

$readmore_border_color: #50585f;
$secondary_border_color: #1091ff;
$show_btn_border: #badfff;
