.panelCalendar {
    width: 2000px;
    margin-bottom: 30px;
    border: none;
    table th {
        text-align: center;
    }

    .p-datepicker-prev {
        display: none;
    }

    .p-datepicker-next {
        display: none;
    }

    .p-highlight {
        color: unset !important;
        background: none !important;
    }

    .p-datepicker-month:enabled:hover,
    .p-datepicker-year:enabled:hover {
        color: #a8c72c !important;
    }
}

.highlighted {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    transition: box-shadow 0.2s;
    border: 1px solid transparent;
    color: #a8c72c;
    // background: #a0ba7e;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
}

.custom-date.clicked {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    transition: box-shadow 0.2s;
    border: 1px solid transparent;
    background-color: #a8c72c;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
}

.calendar_title {
    width: 100% !important;
}

.calendar_date_info {
    margin-bottom: 0 !important;
}

.highlighted,
.custom-date.clicked {
    z-index: 1;
}

.fit_height {
    height: fit-content;
}

.fade-enter {
    .fade-enter {
        opacity: 0.01;
    }

    .fade-enter.fade-enter-active {
        opacity: 1;
        transition: opacity 300ms ease-in;
    }

    .fade-leave {
        opacity: 1;
    }

    .fade-leave.fade-leave-active {
        opacity: 0.01;
        transition: opacity 300ms ease-in;
    }
}

@media screen and (max-width: 724px) {
    .single_post_text {
        padding: 0 !important;
    }
}

.clicked-section {
    min-height: 235px;
}

.clicked-content-wrapper {
    padding-left: 0 !important;
}

.atcb-list-wrapper.atcb-dropdown.atcb-dropoverlay.atcb-style-round {
    width: 87px !important;
}
